/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function() {
          if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);


        // Scroll Up
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });

        $('#auction-type').change(function() {
          var url = $(this).val();
          if(url === 'all') {
              window.location = '/general-auctions';
          } else {
              window.location = '/auction-type/' + url;
          }
          return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'single_real_estate_auctions': {
      init: function() {
          // JavaScript to be fired on the home page

          $('#listingGallery').lightSlider({
          gallery:true,
          item:1,
          loop:true,
          thumbItem:4,
          slideMargin:0,
          enableDrag: false,
          currentPagerPosition:'left',
          onSliderLoad: function(el) {
              el.lightGallery({
                  selector: '#listingGallery .lslide'
              });
          }
      });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'single_general_auctions': {
      init: function() {
          // JavaScript to be fired on the home page

          $('#listingGallery').lightSlider({
          gallery:true,
          item:1,
          loop:true,
          thumbItem:4,
          slideMargin:0,
          enableDrag: false,
          currentPagerPosition:'left',
          onSliderLoad: function(el) {
              el.lightGallery({
                  selector: '#listingGallery .lslide'
              });
          }
      });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'southern_maine_auto_auction': {
      init: function() {

        var map;
         var infowindow = new google.maps.InfoWindow();

         function initialize_gmap(bound) {
           var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

           var isDraggable = w > 480 ? true : false;

           var settings = {
             zoom: 7,
             center: bound.getCenter(),
             scrollwheel: false,
             draggable: isDraggable,
             mapTypeControl: false,
             mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
             navigationControl: false,
             navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
             mapTypeId: google.maps.MapTypeId.ROADMAP};

           map = new google.maps.Map(document.getElementById("map_canvas"), settings);

           var center;
           function calculateCenter() {
             center = map.getCenter();
           }
           google.maps.event.addDomListener(map, 'idle', function() {
             calculateCenter();
           });
           google.maps.event.addDomListener(window, 'resize', function() {
             map.setCenter(center);
           });
         }

         function addMarker(point_lat, point_long, address, title, directions) {

           var point = new google.maps.LatLng(point_lat, point_long);

           var contentString = '<div id="gmapContent" style="text-align:left;">'+
             '<a href="' + directions + '" target="_blank"><h4>' + title + '<br><small>(Click For Directions)</small></h4></a>'+
             '<div id="bodyContent">'+
             '<p>' + address + '</p>'+
             '</div>'+
             '</div>';

           var companyMarker = new google.maps.Marker({
             position: point,
             map: map,
             title: title});

           google.maps.event.addListener(companyMarker, 'click', function() {
             infowindow.setContent(contentString);
             infowindow.open(map,this);
           });

         }

         var locations = [];

         $('.location-map').each(function() {
           var location = [];
           location.push(parseFloat($(this).find('.map-data').find('.point_lat').data('pointlat')));
           location.push(parseFloat($(this).find('.map-data').find('.point_long').data('pointlong')));
           location.push($(this).find('.map-data').find('.address').data('address'));
           location.push($(this).find('.map-data').find('.title').data('title'));
           location.push($(this).find('.map-data').find('.directions').data('directions'));
           locations.push(location);
         });

         if($('#map_canvas').length) {
           var bound = new google.maps.LatLngBounds();
           for (i = 0; i < locations.length; i++) {
             bound.extend( new google.maps.LatLng(locations[i][0], locations[i][1]) );
           }
           initialize_gmap(bound);
           for (i = 0; i < locations.length; i++) {
             addMarker(locations[i][0], locations[i][1], locations[i][2], locations[i][3], locations[i][4]);
           }
         }

      }
    },
    // Contact us page
    'contact': {
      init: function() {
        // JavaScript to be fired on the contact us page

        var map;
         var infowindow = new google.maps.InfoWindow();

         function initialize_gmap(bound) {
           var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

           var isDraggable = w > 480 ? true : false;

           var settings = {
             zoom: 7,
             center: bound.getCenter(),
             scrollwheel: false,
             draggable: isDraggable,
             mapTypeControl: false,
             mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
             navigationControl: false,
             navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
             mapTypeId: google.maps.MapTypeId.ROADMAP};

           map = new google.maps.Map(document.getElementById("map_canvas"), settings);

           var center;
           function calculateCenter() {
             center = map.getCenter();
           }
           google.maps.event.addDomListener(map, 'idle', function() {
             calculateCenter();
           });
           google.maps.event.addDomListener(window, 'resize', function() {
             map.setCenter(center);
           });
         }

         function addMarker(point_lat, point_long, address, title, directions) {

           var point = new google.maps.LatLng(point_lat, point_long);

           var contentString = '<div id="gmapContent" style="text-align:left;">'+
             '<a href="' + directions + '" target="_blank"><h4>' + title + '<br><small>(Click For Directions)</small></h4></a>'+
             '<div id="bodyContent">'+
             '<p>' + address + '</p>'+
             '</div>'+
             '</div>';

           var companyMarker = new google.maps.Marker({
             position: point,
             map: map,
             title: title});

           google.maps.event.addListener(companyMarker, 'click', function() {
             infowindow.setContent(contentString);
             infowindow.open(map,this);
           });

         }

         var locations = [];

         $('.location-map').each(function() {
           var location = [];
           location.push(parseFloat($(this).find('.map-data').find('.point_lat').data('pointlat')));
           location.push(parseFloat($(this).find('.map-data').find('.point_long').data('pointlong')));
           location.push($(this).find('.map-data').find('.address').data('address'));
           location.push($(this).find('.map-data').find('.title').data('title'));
           location.push($(this).find('.map-data').find('.directions').data('directions'));
           locations.push(location);
         });

         if($('#map_canvas').length) {
           var bound = new google.maps.LatLngBounds();
           for (i = 0; i < locations.length; i++) {
             bound.extend( new google.maps.LatLng(locations[i][0], locations[i][1]) );
           }
           initialize_gmap(bound);
           for (i = 0; i < locations.length; i++) {
             addMarker(locations[i][0], locations[i][1], locations[i][2], locations[i][3], locations[i][4]);
           }
         }


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
